.menu-home-template-bar-container-item {
    width: 100%;
    height: 52px; // Altura base de la barra del menú
    position: fixed;
    top: 0;
    background-color: #fff; // Fondo blanco para el menú
    border-radius: 0 0 20px 20px;
    transition: height 0.3s ease; // Suaviza la transición del cambio de altura
    z-index: 101;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
    display: flex;

    .menu-home-template-bar-container-item-left {
        width: auto;
        padding-left: 12px;
    }

    .menu-home-template-bar-container-item-center {
        width: 33.333333333333%;
        flex-grow: 1;
        padding: 0 20px;
        display: flex;
        align-items: center;

        .menu-home-template-bar-container-item-center-search {
            width: 100%;
            height: 70%;
            border-radius: 20px;
            background-color: #EEEEEE;

            input {
                background-color: transparent;
                height: 100%;
                border: none;
                outline: none !important;
                padding: 0 28px;

                &:hover {
                    outline: none;
                }
            }
        }
    }

    .menu-home-template-bar-container-item-right {
        width: auto;
        display: flex;
        justify-content: flex-end;

        .menu-home-template-bar-container-item-right{
            width: 100px;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            p{
                padding: 0;
                margin: 0;

                cursor: pointer;
            }
        }
    }
}

// Ocultar elementos con la clase lateral-constrain
.lateral-constrain {
    display: none; // Oculta este elemento
}

// Media query para pantallas menores a 1050px
@media (max-width: 1050px) {
    .menu-home-template-bar-container-item {
        .menu-home-template-bar-container-item-center {
            padding: 0 8px;
        }
    }
}
