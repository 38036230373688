.fideya-logo-container-text {
    width: 100%;
    height: 100dvh; // Usa la altura completa de la vista
    padding-top: 58px;
    display: flex;
    align-items: center; // Centra verticalmente
    justify-content: center; // Centra horizontalmente
    background-color: transparent; // Color de fondo

    .fideya-logo-container-text-content{

        text-align: center;

        h1{
            span{
                color: #FF9F11;
            }
        }

    }
}
