.search-commerce-template-container{

    position: fixed;
    z-index: 100;

    margin: 0;
    padding: 62px  0 0 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100dvh;

    background-color: rgba($color: #000000, $alpha: 0.5);

    overflow: scroll;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .search-commerce-template-container-content{
        width: 32%;
        height: calc(100% - 62px);
        overflow: scroll;

        border-radius: 12px;
        padding: 12px;
        
        background-color: #fff;

        display: flex;
        flex-direction: column;

        .search-commerce-template-container-content-item{
            display: flex;
            width: 100%;
            height: 50px;
            padding: 32px 0;
            border-radius: 8px;
            margin-bottom: 12px;
            // border: 1px solid black;

            .search-commerce-template-container-content-item-img-container{
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;

                .search-commerce-template-container-content-item-img-container-img{
                    height: 80px;
                    width: 80px;
                    aspect-ratio: 1;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 40px;
                        border-radius: 50px;
                    }
                }


            }

            .search-commerce-template-container-content-item-text-container{
                width: 80%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                p{
                    padding: 0;
                    margin: 0;
                }
            }

            &:hover {
                background-color: #EEEEEE;
                cursor: pointer;
            }
            
        }
    }

}

@media (max-width:1050px) {
    .search-commerce-template-container{
        .search-commerce-template-container-content{
            width: 90% !important;
            
        }
    }
}