* {
    box-sizing: border-box;
}

.forgot-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;

    .forgot-container-buttons {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 4rem;
            span {
                color: #FF7F11;
            }
        }

        padding: 80px 0px;

        .forgot-container-buttons-cta {
            background-color: #fff;
            height: 100%;
            width: 50%;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            padding: 12px 40px;

            p{
                width: 100%;
                padding-bottom: 40px;
            }

            h3{
                width: 100%;
                text-align: center;
            }

            justify-content: center;

            .forgot-container-buttons-cta-form {
                width: 100%;

                padding-top: 28px;

                .forgot-container-buttons-cta-form-input {
                    height: 52px;
                    border: 1px solid #bebebe;
                    border-radius: 8px;
                    margin-bottom: 12px;

                    overflow: hidden;

                    input{
                        border: none;
                        height: 100%;
                        width: 100%;

                        padding: 0 12px;

                        font-size: 1.1rem;
                    }
                }

                .forgot-container-buttons-cta-form-button{
                    height: 52px;
                    background-color: #89023E;
                    border: none;
                    border-radius: 8px;
                    margin-bottom: 12px;
                    width: 100%;
                    font-size: 1.1rem;
                    color: #fff;
    
                    cursor:pointer;
                    margin-top: 20px;

                }
            }

            .forgot-container-buttons-cta-registrarse{
                height: 52px;
                background-color: #FFF;
                border: 1px solid #89023E;
                border-radius: 8px;
                margin-bottom: 12px;
                width: 100%;
                font-size: 1.1rem;
                color: #89023E;

                cursor:pointer;
                margin-top: 40px;
            }
        }

        p {
            text-align: center;
            margin-top: 12px;
        }
    }
}

@media (max-width: 1050px) {
    .forgot-container {
        flex-direction: column;

        .forgot-container-buttons {
            width: 100%;
            height: 100%;

            h1{
                font-size: 3rem;
            }

            .forgot-container-buttons-cta {
                height: auto;
                width: 90%;
                height: 80%;

                padding: 12px;


                .forgot-container-buttons-cta-forgot-pass{
                    width: 100%;
                }
            }

            p {
                width: 80%;
            }
        }
    }
}
