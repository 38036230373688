.success-container{
    width: 100vw;
    height: 100dvh;

    position:fixed;
    top: 0;
    left: 0;

    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 200;
    display: flex;

    justify-content: center;
    align-items: center;

    .success-container-item{
        max-width: 80%;
        width: 400px;
        aspect-ratio: 2;
        
        background-color: #fff;
        border-radius: 12px;

        display: flex;

        overflow: hidden;
        .success-container-item-line{
            height: 100%;
            width: 12px;
            background-color: greenyellow;

        }
        .success-container-item-content{
            height: 100%;
            flex-grow: 1;

            padding:12px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;

            .success-container-item-content-title{
                margin-bottom: 24px;
            }
            .success-container-item-content-message{
                margin:0;
                padding:0;
            }
            
            .success-container-item-content-instruction{
                font-size: smaller;
                margin: 0;
                padding: 0;
                
            }
        }

    }
}