.menu-template-container{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100dvh;

    background-color: #EEEEEE;

    overflow: scroll;

    display: flex;
    flex-wrap: wrap;

    .menu-template-container-content{
        width: 88%;
        flex-grow: 1;
    }

    .menu-template-container-lateral-menu{
        width: 12%;
        flex-grow: 1;
    }

}

@media (max-width:1050px) {
    .menu-template-container{
    
        .menu-template-container-content{
            width: 100%;
            height: 80%;
            flex-grow: 1;
        }
    
        .menu-template-container-lateral-menu{
            width: 100%;
            height: 20%;
            flex-grow: 1;
        }
    
    }
}