.cashback-container {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .cashback-container-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 4px;
        height: auto;

        .cashback-container-info-item {
            width: 100%;
            margin-bottom: 0;

            .cashback-container-info-item-name {
                font-size: 1.3rem;
                margin: 0;
                font-weight: bold;
            }
            .cashback-container-info-item-value {
                font-size: small;
                margin: 0;
            }

            p {
                text-align: center;
            }
        }
    }
    
    .cashback-container-form {
        height: 90%;
        width: 100%;

        display: flex;
        justify-content: center;
        

        form {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 70%;
            padding-top: 8%;

            .cashback-buttons-cta-form-input-container {
                flex-basis: calc(50% - 4px);
                flex-grow: 1;
                box-sizing: border-box;

                p {
                    font-size: small;
                    width: 100%;
                    margin: 0;
                }

                .cashback-buttons-cta-form-input {
                    height: 52px;
                    border: 1px solid #bebebe;
                    border-radius: 8px;
                    margin-bottom: 12px;
                    width: 100%;
                    overflow: hidden;

                    input {
                        border: none;
                        height: 100%;
                        width: 100%;
                        padding: 0 12px;
                        font-size: 1.1rem;
                    }
                }
            }

            .cashback-buttons-cta-form-button,
            .cashback-buttons-cta-form-anti-button {
                height: 52px;
                border-radius: 8px;
                width: 100%;
                font-size: 1.1rem;
                cursor: pointer;
                margin: 0;
            }

            .cashback-buttons-cta-form-button {
                background-color: #89023E;
                border: none;
                color: #fff;
            }

            .cashback-buttons-cta-form-anti-button {
                background-color: transparent;
                border: 1px solid #89023E;
                color: #89023E;
            }
        }
    }
}

@media (max-width: 1050px) {
    .cashback-container {
        padding: 0;
    }

    .cashback-container-form {
        form {
            padding-top: 20px !important;
            width: 100%;
            max-width: 100% !important;
        }
    }

    .cashback-buttons-cta-form-input-container {
        flex-basis: 100% !important;
        gap: 4px;
        margin: 0;
        padding: 0;
    }
}

.cashback-buttons-cta-form-input-container-checkbox {
    display: flex;
    width: 100%;

    .form-check {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 12px;
        padding: 0;

        .custom-checkbox {
            appearance: none;
            width: 20px;
            height: 20px;
            border: 2px solid #000;
            border-radius: 4px;
            background-color: #fff;
            cursor: pointer;
            position: relative;
            transition: background-color 0.3s ease-in-out;
            margin-right: 8px;

            &:checked {
                background-color: #000;

                &::after {
                    content: "\2713";
                    color: white;
                    font-size: 12px;
                    position: absolute;
                    top: 0;
                    left: 3px;
                }
            }
        }
    }
}
