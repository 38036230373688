.benefits-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 68px 16px 16px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    .benefits-canvas {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        background-color: #fff;
        padding: 28px;
        display: flex;
        flex-direction: column;

        overflow: scroll;

        .benefits-title {
            width: 100%;
            height: 30%;

            padding: 8px 0;

            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; 

            .benefits-title-text{
                display: flex;
                align-items: center;

                img{
                    margin-right: 12px;
                    cursor: pointer;
                }
            }

            .benefits-title-selector{
                width: 100%;
            }


                input, select {
                    height: 48px;
                    border-radius: 8px;
                    width: 100%;
                    padding: 12px;
                    margin-bottom: 10px;
                    border: 1px solid #ccc;
                    transition: border-color 0.3s, background-color 0.3s;
                    text-align: left;

                    &:focus {
                        outline: none !important;
                        border-color: #89023E;
                        background-color: #f8f9fa;
                        box-shadow: 0 0 5px rgba(137, 2, 62, 0.5);
                    }
                }

                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="%23999" d="M6 8.25L3.75 5h6.5L6 8.25z"/></svg>');
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                    background-size: 10px;
                }

                img {
                    width: 20px;
                }
            
        }


        .benefits-table {
            width: 100%;
            height: 70%;
            // border: 1px solid #bebebe; // Optional: You may want to keep this for outer border
            border-radius: 12px; // Optional: Keep this for rounded corners on outer border

            margin: 0px; // Remove margin

            table {
                width: 100%;
                height: auto; // Adjusts to content size
                border-collapse: collapse; // Collapse borders to make them connected
                margin: 0px; // Remove margin
                padding: 0px; // Remove padding

                th, td {
                    padding: 8px 12px; // Adjust padding as needed
                    border: 1px solid #f0f0f0; // Border for each cell, make sure this matches what you want
                    margin: 0; // Ensure no margin is added around cells
                }

                th {
                    text-align: left;
                    position: sticky;
                    top: 0; // Keeps header fixed on scroll
                    background-color: #f0f0f0; // Gray background
                    z-index: 1; // Ensure header is above content
                }

                tbody tr {
                    height: auto; // Adjusts to content size
                }
            }
        }
    }
}

@media (max-width: 1050px) {
    .benefits-container {
        padding: 16px;
    }

    .benefits-title{
        margin-bottom: 20px;
    }
}

@media (max-width: 750px) {
    .display-none {
        display: none;
    }
}
