.menu-template-bar-container-item {
    width: 100%;
    height: 52px; // Altura base de la barra del menú
    position: fixed;
    top: 0;
    background-color: #fff; // Fondo blanco para el menú
    border-radius: 0 0 20px 20px;
    transition: height 0.3s ease; // Suaviza la transición del cambio de altura
    z-index: 101;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.05);
      

    display: flex;

    .menu-template-bar-container-item-left{
        width: 33.333333333333%;
        padding-left: 12px;

    }
    .menu-template-bar-container-item-center{
        width: 33.333333333333%;

    }
    .menu-template-bar-container-item-rigth{
        width: 33.333333333333%;

        display: flex;
        justify-content: flex-end;

    }

}

// Media query para pantallas menores a 1050px
@media (max-width: 1050px) {
    .menu-template-bar-container-item {
        &:hover {
            // height: 60%; // Ajusta esta altura según tus necesidades
        }
    }
}
