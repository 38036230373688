.menu-template-icon-container {
    height: 100%;
    display: flex;
    flex-direction: column; // Alinear íconos y línea en columna
    align-items: flex-start; // Centrar el contenido
    justify-content: center;
    cursor: pointer; // Cambiar el cursor al pasar el mouse

    margin-right: 8px;
    
    img {
        width: 20px; // Ajustar el tamaño del ícono según sea necesario
        height: 20px; // Ajustar el tamaño del ícono según sea necesario
    }

    &.active {
        // Aquí puedes agregar estilos adicionales si deseas cambiar el color del ícono o su tamaño
    }

    .active-line {
        width: 100%; // O la longitud que desees
        height: 2px; // Altura de la línea
        background-color: #007bff; // Color de la línea, puedes cambiarlo
        margin-top: 4px; // Espacio entre el ícono y la línea
    }
}

.menu-container {
    display: flex; // Alinear íconos horizontalmente
    gap: 10px; // Espacio entre íconos
}
