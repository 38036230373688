.customer-cashback-container{
    height: auto;
    max-height: 100%;
    width: 100%;
    padding: 68px 12px 12px 12px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap:12px;

    overflow-y: scroll;

    .customer-cashback-card{
        width: 20%;
        min-height: 100px;
        max-height: 120px;
        height: auto;
        background-color: #fff;

        border-radius: 12px;
        padding: 12px;

        p{
            font-size: small;
            margin: 0;
            padding: 0;
        }

        .customer-cashback-card-name{
            font-size: 1.2rem;
            margin: 0 0 12px 0;
            padding: 0;
        }

    }
}

@media (max-width: 1050px) {
    .customer-cashback-container{
        padding: 12px;
        .customer-cashback-card{
            width: calc(100%/3);
    
        }
    }
    
}

@media (max-width: 750px) {
    .customer-cashback-container{
        .customer-cashback-card{
            width: calc(100%/2);
    
        }
    }
    
}

@media (max-width: 450px) {
    .customer-cashback-container{
        .customer-cashback-card{
            width: calc(100%);
    
        }
    }
    
}