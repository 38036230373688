.lateral-menu-container {
    width: 100%;
    height: 100%;
    padding: 68px 12px 12px 12px;
    display: flex;

    .lateral-menu-container-items {
        height: 100%;
        width: 100%;
        background-color: #fff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;

        .lateral-menu-container-items-item {
            width: 100%;
            background-color: antiquewhite;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 8px;
            border-radius: 8px;
            padding: 4px 12px;
            cursor: pointer;

            img {
                margin-right: 12px;
            }
        }
    }
}

// Responsive layout for smaller screens
@media (max-width: 1050px) {
    .lateral-menu-container {
        .lateral-menu-container-items {
            flex-direction: row;
            gap: 8px;
            padding: 8px;

            .lateral-menu-container-items-item {
                width: auto;
                height: auto;
                flex-direction: row;
                margin-bottom: 0;
                padding: 12px;

                span {
                    display: none;
                }

                img {
                    margin: 0;
                }
            }
        }
    }
}
