.create-commerce-container {
    height: 100%;
    width: 100%;
    padding: 68px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .create-commerce-canvas {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .create-commerce-canvas-container {
            background-color: #EEEEEE;
            display: flex;
            align-items: flex-start;

            .create-commerce-container-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                h1 {
                    font-size: 4rem;
                    span {
                        color: #FF7F11;
                    }
                }

                .create-commerce-container-buttons-cta {
                    background-color: #fff;
                    border-radius: 12px;
                    padding: 12px 40px;
                    display: flex;
                    flex-direction: column;

                    p {
                        padding-bottom: 12px;
                    }

                    .create-commerce-container-buttons-cta-form {
                        padding-top: 28px;

                        .create-commerce-container-buttons-cta-form-input {
                            height: 48px;
                            display: flex;
                            margin-bottom: 12px;
                            border: 1px solid #bebebe;
                            border-radius: 8px;
                            overflow: hidden;

                            .toggle-password-button {
                                background: red;
                                border: none;
                                color: blue;
                                cursor: pointer;
                            }

                            input {
                                border: none;
                                padding: 0 12px;
                                font-size: 1.1rem;
                            }
                        }

                        .create-commerce-container-buttons-cta-form-button {
                            height: 52px;
                            background-color: #89023E;
                            border: none;
                            border-radius: 8px;
                            margin-bottom: 12px;
                            width: 100%;
                            font-size: 1.1rem;
                            color: #fff;
                            cursor: pointer;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

.create-commerce-template-canvas-pagination-buttons {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;

    .page-button {
        height: 40px;
        width: 40px;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #000;
        color: white;
        border: none;
        transition: background-color 0.3s;
        font-weight: bold;
        font-size: 12px;

        &:hover {
            background-color: #a03050;
        }

        &:disabled {
            background-color: #e9ecef;
            color: #6c757d;
            cursor: not-allowed;
        }
    }
}


@media (max-width: 1050px) {
    .create-commerce-container {
        padding: 16px;

        .create-commerce-canvas {
            padding: 0;
    
            .create-commerce-container {

                .create-commerce-container-buttons {
    
                    .create-commerce-container-buttons-cta {

                        .create-commerce-container-buttons-cta-form {
    
                            .create-commerce-container-buttons-cta-form-input {
    
                                .toggle-password-button {

                                }

                            }
    
                            .create-commerce-container-buttons-cta-form-button {

                            }
                        }
                    }
                }
            }
        }

    //     .create-commerce-container-buttons {
    //         h1 {
    //             font-size: 3rem;
    //         }

    //         .create-commerce-container-buttons-cta {
    //             width: 90%;
    //             padding: 12px;
    //         }
    //     }
    // }
}
}


// Agrupando estilos para input y select
input, select {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    margin-bottom: 10px; // Manteniendo el margen inferior
    border: 1px solid #ccc;
    transition: border-color 0.3s, background-color 0.3s;
    text-align: left;

    &:focus {
        outline: none;
        border-color: #89023E;
        background-color: #f8f9fa;
        box-shadow: 0 0 5px rgba(137, 2, 62, 0.5);
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="%23999" d="M6 8.25L3.75 5h6.5L6 8.25z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;

    // Este ajuste asegura que el margen inferior funcione correctamente
    margin-bottom: 12px; // Se puede ajustar este valor si es necesario
}

.custom-file-input-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;

    width: 100%;
}

.custom-file-input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
}

.custom-file-label {
    width: 100%;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #89023E;
    color: #89023E;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
}

.custom-file-label:hover {
    background-color: #0056b3; /* Cambia el color del hover según prefieras */
    
}
