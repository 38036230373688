.menu-container{
    height: 100%;

    padding: 0 24px;

}
@media (max-width:1050px) {
    .menu-container{
        padding: 0 8px;
    }
}