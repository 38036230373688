.customer-template-container {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 68px 12px 12px 12px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    .customer-template-canvas {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        background-color: #fff;
        padding: 28px;
        display: flex;
        flex-direction: column;

        .customer-template-canvas-title {
            width: 100%;
            height: 16%;
 
            padding: 16px 0;

            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            h2{
                margin:0;
                padding: 0;
            }

            .customer-template-canvas-title-search{
                display: flex;
                justify-content: space-between;
                width: 50%;
                background-color: #f0f0f0;
                border-radius: 8px;

                height: 100%;

                padding: 0 8px;

                input{
                    width: 10px;
                    height: 100%;
                    flex-grow: 1;

                    border: none;

                    background-color: transparent;
                    outline: none;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }


                }

                img{
                    width: 20px;
                }
            }
        }

        .customer-template-canvas-pagination{
            height: 14%;

            display: flex;
            justify-content: space-between;

            .customer-template-canvas-pagination-selector{
                width: 100px;
                padding: 8px 0;
                input, select {
                    height: 100%;
                    border-radius: 8px;
                    width: 100%;
                    padding: 12px;
                    margin-bottom: 10px;
                    border: 1px solid #ccc;
                    transition: border-color 0.3s, background-color 0.3s;
                    text-align: left;
            
                    &:focus {
                        outline: none !important;
                        // border-color: #89023E;
                        background-color: #f8f9fa;
                        // box-shadow: 0 0 5px rgba(137, 2, 62, 0.5);
                    }
                }
            
                select {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="%23999" d="M6 8.25L3.75 5h6.5L6 8.25z"/></svg>');
                    background-repeat: no-repeat;
                    background-position: right 10px center;
                    background-size: 10px;
                }
            }

            .customer-template-canvas-pagination-buttons{
                display: flex;
                padding: 8px 0;

                .pagination-info {
                    height: 40px;
                    margin: 0 15px;
                    font-weight: bold;
                    color: #555;
                    display: flex;
                    align-items: center;
                }

                .page-button {
                    height: 40px;
                    width: 40px;

                    cursor: pointer;
                    padding: 10px 20px;
                    border-radius: 8px;
                    background-color: #000;
                    color: white;
                    border: none;
                    transition: background-color 0.3s;
                    font-weight: bold;
                    font-size: 12px;
        
                    &:hover {
                        background-color: #a03050;
                    }
        
                    &:disabled {
                        background-color: #e9ecef;
                        color: #6c757d;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .customer-template-canvas-table {
            width: 100%;
            height: 80%;
            border: 1px solid #bebebe; // Optional: You may want to keep this for outer border
            border-radius: 12px; // Optional: Keep this for rounded corners on outer border
        
            overflow: auto; // Use auto to handle overflow
            margin: 0px; // Remove margin
        
            table {
                width: 100%;
                height: auto; // Adjusts to content size
                border-collapse: collapse; // Collapse borders to make them connected
                margin: 0px; // Remove margin
                padding: 0px; // Remove padding
        
                th, td {
                    padding: 8px 12px;
                    border: 1px solid #f0f0f0;
                    margin: 0;
                    font-size: 0.8rem;
                }
        
                th {
                    text-align: left;
                    position: sticky;
                    top: 0; // Keeps header fixed on scroll
                    background-color: #f0f0f0; // Gray background
                    z-index: 1; // Ensure header is above content
                }
                
        
                tbody tr {
                    height: auto; // Adjusts to content size
                }
                tr{
                    cursor: pointer;
                }
            }
        }
        
    }
}

@media (max-width: 1050px) {
    .customer-template-container {
        padding: 12px;
    }
}

@media (max-width: 750px) {
    .display-none{
        display: none;
    }
}

