.commerce-place-container{
    display: flex;
    height: 100dvh;
    width: 100%;

    padding-top: 58px;

    .commerce-place-container-blocks{

        display: flex;
        width: 100%;
        min-height: calc(100% - 58px);
        padding-top: 20px;

        justify-content: center;
        align-items: flex-start;

        .commerce-place-container-blocks-profile{
            width: 40%;
            height:85%;
            background-color: #fff;
            border-radius: 12px;

            padding: 12px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .commerce-place-container-blocks-profile-portada{
                height: 90%;
                width: 100%;
                border-radius: 8px;
                overflow: hidden;
                background-color: #eeeeee;

                img{
                    width: 100%; /* Se adapta al ancho del contenedor */
                    height: 100%; /* Se adapta a la altura del contenedor */
                    object-fit:cover; /* Cubre el contenedor sin deformarse */
                    object-position: center; /* Centra la imagen en caso de que se recorte */
                }
            }

            .commerce-place-container-blocks-profile-info{

                height: 15%;
                width: 100%;
                border-radius: 8px;

                // background-color: #eeeeee;
                display: flex;
                flex-direction: column;

                .commerce-place-container-blocks-profile-info-name-follow{
                    width: 100%;
                    display: flex;
                    margin-top: 8px;

                    display: flex;
                    justify-content: space-between;

                    .commerce-place-container-blocks-profile-info-name-follow-container{
                        display: flex;
                        p{
                            font-size: small;
                            margin-right: 12px;
                        }
                    }

                    .commerce-place-container-blocks-profile-info-name-follow-button{
                        width: 40px;
                        height: 20px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        border-radius: 20px;
                        background-color: #000;

                        .commerce-place-container-blocks-profile-info-name-follow-button-icon{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%; /* o la altura que necesites */
                            width: 100%; /* o el ancho que necesites */
                            color: #fff;
                            font-weight: bold;

                        }

                        &:hover{
                            cursor:pointer;
                        }
                        
                    }

                    .commerce-place-container-blocks-profile-info-name-follow-button-blocked{
                        width: 40px;
                        height: 20px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        border-radius: 20px;
                        background-color: #fff;
                        border: 1px solid black;

                        .commerce-place-container-blocks-profile-info-name-follow-button-icon{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%; /* o la altura que necesites */
                            width: 100%; /* o el ancho que necesites */
                            color: #000;
                            font-weight: bold;

                        }

                        &:hover{
                            cursor:not-allowed;
                        }
                        
                    }

                }
                .commerce-place-container-blocks-profile-info-social{
                    width: 100%;
                    height: 300px;

                    display: flex;
                    align-items: flex-end;

                    padding-bottom: 8px;

                    .commerce-place-container-blocks-profile-info-social-icon{
                        height: 20px;
                        width: 20px;
                        margin-right: 20px;
                    }

                }
            
            }
        }


    }
}
@media (max-width:1050px) {
    .commerce-place-container{
    
        .commerce-place-container-blocks{
    
            .commerce-place-container-blocks-profile{
                width: 90%;
                height: 80%;
    
                .commerce-place-container-blocks-profile-portada{
                    height: 80%;
                }
    
                .commerce-place-container-blocks-profile-info{
                    height: 20%;
    
                    .commerce-place-container-blocks-profile-info-name-follow{
    
                        .commerce-place-container-blocks-profile-info-name-follow-button{
    
                            .commerce-place-container-blocks-profile-info-name-follow-button-icon{
    
                            }
                        }
    
                    }
                    .commerce-place-container-blocks-profile-info-social{

                        }
    
                    }
                
                }
            }
    
    
        }
    }
