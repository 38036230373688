* {
    box-sizing: border-box;
}

.welcome-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    display: flex;

    .welcome-container-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;

        .welcome-container-text-content {
            width: 90%;
            max-width: 600px;
            height: auto;
            max-height: 800px;

            h1 {
                font-size: 4rem;
                span {
                    color: #FF7F11;
                }
            }

            p {
                font-size: 2rem;
            }

            .welcome-container-text-content-cta{
                color: #89023E;
                font-weight: bold;

                cursor: pointer;
                width: auto;
            }
        }
    }

    .welcome-container-buttons {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .welcome-container-buttons-cta {
            background-color: #fff;
            height: 60%;
            width: 60%;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            padding: 12px;

            .welcome-container-buttons-cta-form {
                width: 100%;

                padding-top: 28px;

                .welcome-container-buttons-cta-form-input {
                    height: 52px;
                    border: 1px solid #bebebe;
                    border-radius: 8px;
                    margin-bottom: 12px;

                    overflow: hidden;

                    input{
                        border: none;
                        height: 100%;
                        width: 100%;

                        padding: 0 12px;

                        font-size: 1.1rem;
                    }
                }

                .welcome-container-buttons-cta-form-button{
                    height: 52px;
                    background-color: #89023E;
                    border: none;
                    border-radius: 8px;
                    margin-bottom: 12px;
                    width: 100%;
                    font-size: 1.1rem;
                    color: #fff;
    
                    cursor:pointer;
                    margin-top: 20px;

                }
            }

            .welcome-container-buttons-cta-registrarse{
                height: 52px;
                background-color: #FFF;
                border: 1px solid #89023E;
                border-radius: 8px;
                margin-bottom: 12px;
                width: 100%;
                font-size: 1.1rem;
                color: #89023E;

                cursor:pointer;
                margin-top: 40px;
            }
        }

        p {
            text-align: center;
            margin-top: 12px;
        }
    }
}

@media (max-width: 1050px) {
    .welcome-container {
        flex-direction: column;

        .welcome-container-text {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .welcome-container-text-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h1{
                    font-size: 3rem;
                }

                p {
                    display: none; // Hide paragraph on smaller screens
                }
                .welcome-container-text-content-cta{
                    display: block;
                    font-size: 1rem;
                    margin:0;
                }


            }
        }

        .welcome-container-buttons {
            width: 100%;
            height: 80%;

            .welcome-container-buttons-cta {
                height: auto;
                width: 90%;
                height: 80%;

                .welcome-container-buttons-cta-forgot-pass{
                    width: 100%;
                }
            }

            p {
                width: 80%;
            }
        }
    }
}
