* {
    box-sizing: border-box;
}

.create-account-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #EEEEEE;
    display: flex;
    align-items: center;

    .create-account-container-buttons {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 4rem;

            span {
                color: #FF7F11;
            }
        }

        padding: 80px 0px;

        .create-account-container-buttons-cta {
            background-color: #fff;
            height: 100%;
            width: 50%;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            padding: 12px 40px;

            p {
                width: 100%;
                padding-bottom: 40px;
            }

            h3 {
                width: 100%;
                text-align: center;
            }

            justify-content: center;

            .create-account-container-buttons-cta-form {
                width: 100%;
                padding-top: 28px;

                .create-account-container-buttons-cta-form-input {
                    
                    p{
                        font-size: smaller;
                        padding: 0;
                        margin: 0;
                        text-align: left;
                    }

                    input {
                        border: none;
                        height: 100%;
                        width: 100%;
                        flex-grow: 1 !important;
                        padding: 0 12px;
                        font-size: 1.1rem;
                        flex-grow: 1;

                        height: 52px;
                        border: 1px solid #bebebe;
                        border-radius: 8px;
                        margin-bottom: 12px;
                        display: flex;
                        overflow: hidden;
                    }
                }

                .password{
                    display: flex;
                    width:100%;

                    .create-account-container-buttons-cta-form-input{
                        flex-grow: 1;
                    }

                    .toggle-password-button {
                        background: #eee;
                        border: none;
                        color: #000;
                        cursor: pointer;
                        margin-left: 5px;
                        margin-top: 8px;
                        font-size: 14px;
                        padding: 0 8px;
                        height: 48px;
                        align-self: center;
                        border-radius: 8px;;
                    }
                }

                .create-account-container-buttons-cta-form-button {
                    height: 52px;
                    background-color: #89023E;
                    border: none;
                    border-radius: 8px;
                    margin-bottom: 12px;
                    width: 100%;
                    font-size: 1.1rem;
                    color: #fff;
                    cursor: pointer;
                    margin-top: 20px;
                }
            }

            .create-account-container-buttons-cta-registrarse {
                height: 52px;
                background-color: #FFF;
                border: 1px solid #89023E;
                border-radius: 8px;
                margin-bottom: 12px;
                width: 100%;
                font-size: 1.1rem;
                color: #89023E;
                cursor: pointer;
                margin-top: 40px;
            }
        }

        p {
            text-align: center;
            margin-top: 12px;
        }
    }
}

@media (max-width: 1050px) {
    .create-account-container {
        flex-direction: column;

        .create-account-container-buttons {
            width: 100%;
            height: 100%;

            h1 {
                font-size: 3rem;
            }

            .create-account-container-buttons-cta {
                height: auto;
                width: 90%;
                height: 80%;
                padding: 12px;

                .create-account-container-buttons-cta-forgot-pass {
                    width: 100%;
                }
            }

            p {
                width: 80%;
            }
        }
    }
}

.content-template-canvas-pagination-buttons {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;

    .page-button {
        height: 40px;
        width: 40px;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #000;
        color: white;
        border: none;
        transition: background-color 0.3s;
        font-weight: bold;
        font-size: 12px;

        &:hover {
            background-color: #a03050;
        }

        &:disabled {
            background-color: #e9ecef;
            color: #6c757d;
            cursor: not-allowed;
        }
    }
}

// Agrupando estilos para input y select
input, select {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    margin-bottom: 10px; // Manteniendo el margen inferior
    border: 1px solid #ccc;
    transition: border-color 0.3s, background-color 0.3s;
    text-align: left;

    &:focus {
        outline: none;
        border-color: #89023E;
        background-color: #f8f9fa;
        box-shadow: 0 0 5px rgba(137, 2, 62, 0.5);
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path fill="%23999" d="M6 8.25L3.75 5h6.5L6 8.25z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;

    // Este ajuste asegura que el margen inferior funcione correctamente
    margin-bottom: 12px; // Se puede ajustar este valor si es necesario
}

.instruction{
    margin: 0 !important;
    padding: 0 !important;
    font-size: smaller;
}

.instruction-head{
    margin: 0 0 24px 0 !important;
    padding: 0 !important;
    font-size: smaller;
}

.handle-back{
    cursor: pointer;
    color:#a03050;
    font-weight: bold;
}